/*
=========================================================
* Classified React - v2.0.0
=========================================================

* Product Page: https://rythz.com/product/material-kit-react
* Copyright 2021 Rythz Lab (https://rythz.com)

Coded by rythz.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Classified React pages
import Presentation from "pages/Presentation";

export default function PresentationPage() {
  return <Presentation />;
}
