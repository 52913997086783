/**
=========================================================
* Classified React - v2.0.0
=========================================================

* Product Page: https://rythz.com/product/material-kit-react
* Copyright 2021 Rythz Lab (https://rythz.com)

Coded by rythz.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Classified React base styles
import borders from "assets/theme/base/borders";

// Classified React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      display: "block",
      padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
      borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
    },
  },
};
