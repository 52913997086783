/**
=========================================================
* Classified React - v2.0.0
=========================================================

* Product Page: https://rythz.com/product/material-kit-react
* Copyright 2021 Rythz Lab (https://rythz.com)

Coded by rythz.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};
