/**
=========================================================
* Classified React - v2.0.0
=========================================================

* Product Page: https://rythz.com/product/material-kit-react
* Copyright 2021 Rythz Lab (https://rythz.com)

Coded by rythz.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Classified React base styles
import typography from "assets/theme/base/typography";

// Classified React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography;

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl,
    },
  },
};
