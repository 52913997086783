/* eslint-disable */
/*
=========================================================
* Classified React - v2.0.0
=========================================================

* Product Page: https://rythz.com/product/material-kit-react
* Copyright 2021 Rythz Lab (https://rythz.com)

Coded by rythz.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

// Classified React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Classified React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SecondaryNavbar from "examples/Navbars/SecondaryNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";
import GridMenu from "pages/Presentation/sections/GridMenu";
import Posts from "pages/LandingPages/Author/sections/Posts";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rgba from "assets/theme/functions/rgba";

const settings = {
  lazyLoad: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, initialSlide: 2, autoplay: true, autoplaySpeed: 2000, centerMode: true, fade: true, //dots: true, 
};

function Presentation() {
  return (
    <>
      {/* <MKBox variant="gradient" bgColor="dark" shadow="sm" > */}
        <DefaultNavbar relative center sticky routes={routes}
          action={{
            type: "internal", // "external",
            route: "/pages/landing-pages/about-us",
            label: "Log In / Sign Up",
            color: "info",
          }}
        />
      {/* </MKBox> */}
      {/* <SecondaryNavbar center routes={routes} // sticky
      /> */}
      <MKBox sx={{paddingLeft: 4, paddingRight: 4, paddingTop: 10, marginBottom: 5}}>
        <Slider {...settings} >
          <MKBox display="flex" borderRadius="xl" minHeight="52vh" // width="100%" // mx={20} // my={0} // py={30} //{6}
            style={{backgroundColor: 'orange', alignItems: 'center'}}
            sx={{backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `url(https://unsplash.com/photos/ULHxWq8reao/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjU4MTUwNjQ0&force=true&h=600)`,
                backgroundSize: "cover", display: "grid", backgroundPosition: "center", //maxWidth: "80%",
            }}
          >
            {/* <Container style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderBottomLeftRadius: 16, borderTopRightRadius: 16}}> */}
              <Grid container item xs={12} lg={6} style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderRadius: 12}}>
                <MKTypography variant="h1" color="white" // mt={-6} // mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {fontSize: size["3xl"],},
                  })}
                >
                  Book Restaurant{" "}
                </MKTypography>
                <MKTypography variant="body1" color="white" // textAlign="center" // px={{ xs: 6, lg: 12 }} // mt={1}
                >
                  The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested
                </MKTypography>
              </Grid>
            {/* </Container> */}
          </MKBox>

          <MKBox display="flex" alignItems="center" borderRadius="xl" minHeight="52vh"
            // width="100%" // mx={20} // my={0} // py={30} //{6}
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `url(https://unsplash.com/photos/fY8Jr4iuPQM/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjU4MTUwNjQ0&force=true&h=600)`,
              backgroundSize: "cover", display: "grid",
              backgroundPosition: "center",
            }}
          >
            {/* <Container style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderBottomLeftRadius: 16, borderTopRightRadius: 16}}> */}
              <Grid container item xs={12} lg={6} style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderRadius: 12}}>
                <MKTypography variant="h1" color="white" // mt={-6} // mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {fontSize: size["3xl"],},
                  })}
                >
                  Hire & Search Jobs{" "}
                </MKTypography>
                <MKTypography variant="body1" color="white" // textAlign="center" // px={{ xs: 6, lg: 12 }} // mt={1}
                >
                  The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested
                </MKTypography>
              </Grid>
            {/* </Container> */}
          </MKBox>

          <MKBox display="flex" alignItems="center" borderRadius="xl" minHeight="52vh"
            // width="100%" // mx={20} // my={0} // py={30} //{6}
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `url(https://unsplash.com/photos/7C0TnJXktVU/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjU4MTUwNjQ0&force=true&h=600)`,
              backgroundSize: "cover", display: "grid",
              backgroundPosition: "center",
            }}
          >
            {/* <Container style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderBottomLeftRadius: 16, borderTopRightRadius: 16}}> */}
              <Grid container item xs={12} lg={6} style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderRadius: 12}}>
                <MKTypography variant="h1" color="white" // mt={-6} // mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {fontSize: size["3xl"],},
                  })}
                >
                  Vacation Top List{" "}
                </MKTypography>
                <MKTypography variant="body1" color="white" // textAlign="center" // px={{ xs: 6, lg: 12 }} // mt={1}
                >
                  The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested
                </MKTypography>
              </Grid>
            {/* </Container> */}
          </MKBox>

          <MKBox display="flex" alignItems="center" borderRadius="xl" minHeight="52vh"
            // width="100%" // mx={20} // my={0} // py={30} //{6}
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `url(https://unsplash.com/photos/NpTbVOkkom8/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjU4MTUwNjQ0&force=true&h=600)`,
              backgroundSize: "cover", display: "grid",
              backgroundPosition: "center",
            }}
          >
            {/* <Container style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderBottomLeftRadius: 16, borderTopRightRadius: 16}}> */}
              <Grid container item xs={12} lg={6} style={{position: 'absolute', left: 0, bottom: 8, padding: 24, backgroundColor: rgba("#000", 0.6), borderRadius: 12}}>
                <MKTypography variant="h1" color="white" // mt={-6} // mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {fontSize: size["3xl"],},
                  })}
                >
                  Search Real Estate{" "}
                </MKTypography>
                <MKTypography variant="body1" color="white" // textAlign="center" // px={{ xs: 6, lg: 12 }} // mt={1}
                >
                  The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested
                </MKTypography>
              </Grid>
            {/* </Container> */}
          </MKBox>
        </Slider>
      </MKBox>

      {/* <Card
        sx={{// p: 2,
          mx: { xs: 2, lg: 3 }, // mt: -8,
          mb: 4,
          // backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          // backdropFilter: "saturate(200%) blur(30px)",
          // boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      > */}
        <GridMenu />
      {/* </Card> */}

      {/* <Divider variant="middle" sx={{mb: 6}} /> */}

      {/* <Posts title={'Upcoming Events'} subtitle={'Register your event on DesiInVidesi'} linkText={'Now'} /> */}
      <Posts title={''} subtitle={''} linkText={''} />

      <MKBox
        minHeight="60vh" //"5vh" //
        width="100%"
        sx={{ marginTop: 8, marginBottom: 8,
          // backgroundImage: `url(${bgImage})`,
          backgroundImage: `url(https://unsplash.com/photos/XKIO6ZgCObo/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjU4MTUwNjQ0&force=true&h=840)`,//`url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center", // "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              // mt={-6}
              // mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Real Estate{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <DesignBlocks />

      <MKBox
        minHeight="60vh" //"5vh" //
        width="100%"
        sx={{ marginTop: 8, marginBottom: 8,
          // backgroundImage: `url(${bgImage})`,
          backgroundImage: `url(https://unsplash.com/photos/XbwHrt87mQ0/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjU4MTUwNjQ0&force=true&h=840)`,//`url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center", // "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              // mt={-6}
              // mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Roommates / Rentals{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      {/* <Card
        sx={{
          // p: 2,
          mx: { xs: 2, lg: 3 },
          // mt: -8,
          mb: 4,
          // backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          // backdropFilter: "saturate(200%) blur(30px)",
          // boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      > */}
        {/* <Counters /> */}
        {/* <Information /> */}
        <Posts title={'Winter Vacation Spots'} subtitle={'Register your event on DesiInVidesi'} linkText={'Avail Discounts'} />
        {/* <Pages /> */}
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        {/* <Container sx={{ mt: 6, mb: 6 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "https://rythz.com/learning-lab/react/overview/material-kit/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "https://rythz.com/learning-lab/react/overview/datepicker/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route: "https://rythz.com/learning-lab/react/alerts/material-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}

        {/* <Testimonials />
        <Download /> */}

        {/* <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20Material%20Design"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://rythz.com/product/material-kit-react"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://rythz.com/product/material-kit-react"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox> */}
      {/* </Card> */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
