/* eslint-disable */
/*
=========================================================
* Ezak Fresh React - v2.0.0
=========================================================

* Product Page: https://rythz.com/product/material-kit-react
* Copyright 2021 Rythz Lab (https://rythz.com)

Coded by rythz.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";

// Ezak Fresh React components
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
// import bgImage from "assets/images/shapes/waves-white.svg";

function GridMenu() {
  return (
    <MKBox component="section" pt={{ xs: 0, sm: 2 }} pb={{ xs: 0, sm: 2 }}>
      <Container>
        <Grid container item xs={12} mx="auto"> {/* xs={6} */}
          <MKBox textAlign="center">
            {/* <MKTypography variant="h3" mt={6} mb={3}>
              Available on these technologies
            </MKTypography> */}
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={2} lg={1}> {/* xs={4} lg={4} */}
                <Tooltip title="Comming soon">
                  <MKBox
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card color="info" sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}> {/* p: 2, px: 3 */}
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/282405/maps-and-flags-pin.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/133725/shopping-cart.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/282434/trucks-moving.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/282298/hand-gesture-hand.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    component="a"
                    href="https://rythz.com/product/material-kit-react"
                    target="_blank"
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/282350/file-storage.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/282359/house-things-vacuum.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/282405/maps-and-flags-pin.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/133725/shopping-cart.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={2} lg={1}>
                <Tooltip title="Comming soon">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="#"
                    target="_blank"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Card sx={{ p: 1, px: 2, borderRadius: 2, boxShadow: 0}}>
                      <MKBox
                        component="img"
                        src="https://www.svgrepo.com/show/282434/trucks-moving.svg"
                        width="100%"
                      />
                    </Card>
                  </MKBox>
                </Tooltip>
              </Grid>

            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default GridMenu;
